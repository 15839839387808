import request from '@/utils/request'

/**
 * 登录 获取租户
 */
export function getTenant(data) {
  console.log(request)
  return request({
      url: `/sys/api/v1/unit/anon/get/${data}/tenant`,
      method: 'get',
      data: {},
      headers: {
        'content-type': 'application/json'
      }
  });
}


/**
 * 登录 获取租户
 */
export function login(data) {

  const params = JSON.stringify(data);
  return request({
      url: '/sys/api/v1/login',
      method: 'post',
      data: params,
      headers: {
        'content-type': 'application/json'
      }
  });
}


// loginByUsername, logout, getUserInfo


/**
 * 登录 loginByUsername
 */
export function loginByUsername(data) {

  const params = JSON.stringify(data);
  return request({
      url: '/sys/api/v1/login',
      method: 'post',
      data: params,
      headers: {
        'content-type': 'application/json'
      }
  });
}
/**
 * 登出 logout
 */
export function logout(data) {

  const params = JSON.stringify(data);
  return request({
      url: '/sys/api/v1/logout',
      method: 'post',
      data: params,
      headers: {
        'content-type': 'application/json'
      }
  });
}
/**
 * 获取当前登录信息
 */
export function getUserInfo(data) {

  let time = new Date().getTime();
  return request({
      url: '/sys/api/v1/user/read/current?'+time,
      method: 'get',
  });
}

/**
 * 获取登录人的菜单权限
 */
export function getMenu(data) {
  const params = JSON.stringify(data);
  return request({
    url: '/sys/api/v1/user/get_menu',
    method: 'post',
    data: params,
    headers: {
      'content-type': 'application/json'
    }
  });
}
/**
 * 找回密码获取验证码
 */
export function findPasswordCheckCode(data) {
  const params = JSON.stringify(data);
  return request({
    url: '/sys/api/v1/public/anon/get/retrieve_password/code',
    method: 'post',
    data: params,
    headers: {
      'content-type': 'application/json'
    }
  });
}
/**
 * 找回密码验证验证码
 */
export function validatePasswordCheckCode(data) {
  const params = JSON.stringify(data);
  return request({
    url: '/sys/api/v1/user/anon/retrieve_password/validate',
    method: 'post',
    data: params,
    headers: {
      'content-type': 'application/json'
    }
  });
}
/**
 * 重置密码
 */
export function resetPassword(data) {
  const params = JSON.stringify(data);
  return request({
    url: '/sys/api/v1/user/anon/retrieve_password/reset',
    method: 'post',
    data: params,
    headers: {
      'content-type': 'application/json'
    }
  });
}

/**
 * 修改密码
 */
 export function setPassword(data) {
  const params = JSON.stringify(data);
  return request({
      url: '/sys/api/v1/user/update/password',
      method: 'post',
      data: params,
      headers: {
        'content-type': 'application/json'
      }
  });
}

/**
 * 获取当前登录人的权限按钮
 */
 export function readMyRoleBtnList(data) {
  const params = JSON.stringify(data);
  return request({
      url: '/sys/api/v1/user/read/role/btn',
      method: 'post',
      data: params,
      headers: {
        'content-type': 'application/json'
      }
  });
}
//

export function appUserGetMenu(data,token) {

  const params = JSON.stringify(data);
  return request({
      url: '/deehow-CMS-Web/user/get_menu',
      method: 'post',
      data: params,
      headers: {
        'content-type': 'application/json',
      }
  });
}
/**
 * 登录
 */
 export function appLoginToWeb(data) {

  return request({
    url: `/sys/api/v1/bridge/${encodeURIComponent(data)}`,
    method: 'get',
    headers:{
      'no-pwd':"true"
    }
    // params: data,
  });
}

/**
 * oa登录
 */
export function oaLoginToWeb(value) {
    return request({
        url: `/sys/api/v1/skip?value=${value}`,
        method: 'get',
        headers:{
            'no-pwd':"true"
        },
        timeout:1000 * 60 * 2
    });
}

/**
 * 获取登录人拥有的菜单
 */
export function getRoleRoutes(params) {
    return request({
        url: `/sys/api/v1/routers`,
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

/**
 * 获取登录人拥有的操作权限
 */
export function getRoleInfos(params) {
    return request({
        url: `/sys/api/v1/infos`,
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

/**
 * 根据id获取用户信息
 */
export function getUserDetail(data) {
    return request({
        url: `/sys/api/v1/user/read/detail`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}
